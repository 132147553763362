@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.ProductReleases {
  margin-top: 1rem;
  margin-right: -2rem;
  display: flex;
  flex-wrap: wrap;

  .ReleaseGroup {
    margin-right: 2rem;
    flex: 0 0 calc(50% - 2rem);
    min-width: 21rem;
    min-height: 15rem;
    margin-bottom: 1rem;

    .info-icon {
      font-size: 1.5rem;

      &:hover {
        color: $kone-blue;
      }
    }

    @include respond-tablet-landscape {
      flex-grow: 1;
    }

    .ant-card-body {
      // padding-top: 0.5rem;
    }

    .group-title {
      margin-bottom: 1rem;
    }

    .group-error-message {
      color: $error-color;
      margin-top: 0.5rem;
      margin-bottom: -0.8rem;
      min-height: 22px;
    }

    .releases-container {
      display: flex;
      flex-wrap: wrap;

      .ReleaseSwitch {
        margin-right: 1.5rem;
        margin-bottom: 1rem;
        display: flex;

        .ReleaseSwitch-label {
          margin-right: 0.75rem;
        }
      }
    }

    .default-selection {
      .default-dropdown {
        margin-left: 1rem;
      }
    }
  }
}