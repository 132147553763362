.CustomSwitch {
  &--buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    .warning-icon {
      font-size: 1.5rem;
      margin-left: 0.5rem;
      cursor: pointer;
    }
  }
}