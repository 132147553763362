.CadRequests {

  .chartAndFilters {
    display: flex;
    flex-direction: row;
    width:100%;

    .chart {        
      flex-basis: 80%;
    }
    .filters {
      flex-basis: 20%;        
    }
  }

  .fullWidth {
    width:100%;
  }
  
  .halfWidth {
    width:50%;
    text-align: center;
  }
  
  .filter {
    padding: 10px 5px;
  
    .filterHeader {
      text-transform: uppercase;
    }
  
    .filterItem {
  
    }
  
  }

  .RequestStatusTable {

    .ant-table-tbody {
      font-size:13px;
    }

  }

  .status-indicator {
    font-weight: bold;
    
    &.Failed {
      color: #fe1313;
    }

    &.Complete {
      color: #52c41a;
    }
    
    &.Ready {
      color: #52c41a;
    }
    
    &.In-progress {
      color: #faad14;
    }

    &.Waiting {
      color: #faad14;
    }

    &.noneState {
      color: #bfbfbf;
    }
  }

  .requests-container {
    margin-top: 10px;
    & > .ant-card-head-wrapper {
      display: flex;
      .ant-card-head-title {
        flex: 1;
      }

      .ant-card-extra {
        flex: 2;
      }
    }
    
    & > .ant-card-body {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      justify-content: flex-start;
      background: #fafafa;

      .ant-card-loading-content {
        padding: 1rem;
        background-color: white;
        width: 100%;
      }
    }
    .RequestStatusTable {
      width: 100%;
      margin-top: 1rem;

      .machine-name {
        font-weight: bold;
      }

      &:nth-of-type(1), &:nth-of-type(2) {
        margin-top: 0;
      }

      &:nth-of-type(2n) {
        margin-left: auto;
      }
    }
  }

  .actionLink {
    cursor: pointer;
    color: #1890ff;
  }

}

.ant-modal-root {
  .modalArea {
    height: 80%;
  }

  .ant-modal-content {
    .ant-modal-body {
      max-height: unset;
      height: 100%;
    }
  }


  .modalView {
    margin: 15px;
  }
  .infoLabel {
    width: 50%;
    font-weight: bold;
    text-align: left;
  }
  .infoValue {
    width: 50%;
    text-align: right;

    &.Failed {
      color: #fe1313;
      font-weight: bold;
    }

    &.Complete {
      color: #52c41a;
      font-weight: bold;
    }
    
    &.Ready {
      color: #52c41a;
      font-weight: bold;
    }
    
    &.In-progress {
      color: #faad14;
      font-weight: bold;
    }

    &.Waiting {
      color: #faad14;
      font-weight: bold;
    }

  }

}
