.ManagePredesignsPage {
  
  .design-set-radio-button-group {
    .design-set-radio-button {
      text-transform: capitalize;
    }
  }

  .themes-container {
    padding: 0;
    margin: 0;
    // width: 30rem;

    .theme-block {
      &:not(:last-of-type) {
        margin-bottom: 1.25rem;
      }

      .predesigns-container {
        display: flex;
        flex-wrap: wrap;

        .predesign-checkbox {
          margin: 0;
          margin-top: .5rem;
          width: 10rem;
        }
      }
    }
  }

  .buttons-container {
    .ant-btn {
      margin-right: .5rem;
    }
  }
}