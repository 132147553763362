@import '../../styles/mixins.scss';

.ManageFrontlinePage {
  .ProductSummaryList {
    margin-top: 1.5rem;
  }

  @include respond-tablet-portrait {
    padding-left: 5%;
  }

  .switch-container {
    display: flex;
    align-items: center;
    
    .switch-label {
      font-weight: 500;
      margin-right: 1rem;
    }
  }
}

.ProductSummaryList {
  .product-summary-card {
    .ant-card-body {
      display: flex;
      justify-content: center;
      align-items: start;
      padding: 12px 16px;
      flex-direction: column;
    }

    .product-name {

    }

    .tags {
      height: 2rem;
      display: flex;
      align-items: flex-end;
      // margin-top: 0.5rem;
      // margin-left: auto;
    }
  }
}