@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';

.LanguageSelection {
  margin-top: 2rem;

  h3 {
    margin-bottom: 1.25rem;
  }

  .language-select {
    width: 18rem;
    margin-right: 1.2rem;
  }

  .current-languages {
    margin-top: 1rem;
    margin-right: -1rem;
    display: flex;
    flex-wrap: wrap;

    .default-language {
      .default-element {
        font-size: 1.2rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding: 5px 1.1rem;

        &:hover { // no hover effect
          color: #52c41a;
          background: #f6ffed;
          border-color: #b7eb8f;
        }
      }
      .ant-card-head {
        // background-color: $kone-blue;
        // color: white;
      }
    }

    .language-card {
      margin-right: 2rem;
      flex: 0 0 calc(50% - 2rem);
      min-width: 21rem;
      margin-bottom: 1rem;

      @include respond-tablet-landscape {
        flex-grow: 1;
      }

    }

    .current-languages-item {
      padding: .75rem 0;
      display: flex;
      // justify-content: space-between;
      align-items: center;

      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
      }

      .languages-data {
        flex: 0 0 12rem;
        
        span {
          display: inline-block;
        }
      }

      .languages-item-buttons {
        display: inline-flex;
        align-items: center;
        width: 100%;
        justify-content: left;

        .switch-container {
          display: flex;
          justify-content: space-between;
          width: 8.6rem;
          // background: orangered;
          margin-right: .5rem;
        }
       
        b.default-element {
          display: inline-block;
          text-align: center;
        }

        .default-element {
          min-width: 8rem;
          background-color: red;
          
        }

        // Give all the immediate child elements
        // inside this div a left margin, except for the
        // first one.
        & > * + * {
          margin-left: 1rem;
        }

        .languages-item-give-revoke {
          min-width: 11rem;
        }
      }
    }
  }
}