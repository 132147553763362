
.LanguagesPage {
  margin: 0 auto;

  .actions-container {
    display: flex;

    .search-field {
      width: 15rem;
      margin: 0rem 1rem 1rem 0
    }
    
    .publish-global-button {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  .checkbox-container {
    // margin-bottom: 3.5rem;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 6rem;
    height: 25rem;
    overflow-y: scroll;
    // justify-content: space-between;

    .ant-card-body {
      width: 100%;

      .list-item {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-height: 2.5rem;
        margin: 0;
        padding: .65rem 0;
        // margin-top: .5rem;
        
        &:not(:last-of-type) {
          border-bottom: 1px solid rgba(0, 0, 0, .1)
        }

        .language-checkbox {
          width: 15rem;
        }

        .tag-container {
          flex: 1 0;
        }

        .publish-container {
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          flex: 0 0 40%;

          button {
            margin-right: 1rem;
            flex: 0 0 25%;
          }

          .publish-text {
            display: inline-block;
            flex: 0 0 55%;
          }
        }
      }
    }
  }
}