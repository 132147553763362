.AddressFieldManager {
  display: flex;
  flex-wrap: wrap;
  margin-right: -2rem;

  .AddressFields, .add-button-container {
    width: calc(50% - 2rem);
    min-width: 32rem;
    margin-right: 2rem;
    margin-bottom: 2.5rem;

    .remove-button {
      margin-left: 1.5rem;
    }

    .EditableInput {
      &:not(:last-of-type) {
        margin-bottom: .5rem;
      }
      
      &__input {
        min-width: 25rem;
      }
    }
  }

  .add-button-container {
    padding-left: 8rem;
  }
}