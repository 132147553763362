@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.UsersDisplay {
  .col-hidden {
    display: none;
  }

  .hover-link {
    & * {
      cursor: pointer;
    }
  }

  .save-button {
    margin-left: 0.45rem;
  }

  .ant-table-footer {
    background: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;


    .email-input-container {
      min-width: 18rem;
    }


    .email-input {
      border: none;
      background: none;
      border-bottom: 1px solid #1890ff;
      border-radius: 0;
      padding: 0;
    }
  }

  .role-tag {
    width: 5rem;
    height: 2rem;
    font-size: 0.9rem;
    // font-weight: bold;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  .role-tag.saving {
    &:hover {
      cursor: not-allowed;
    }
  }

  @include respond-tablet-landscape {

  }
}

.FrontlineTableCell {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__frontlines {
    max-width: 75%;
  }

  .ant-select {
    width: 100%;
    margin-right: auto;
  }

  &.editing {
    // flex-direction: column;
    width: 17rem;

    .editor-buttons {
      display: flex;
      flex-direction: column;
      // justify-content: flex-end;
      // width: 100%;
      margin-left: .25rem;

      .ant-btn {
        width: 4.5rem;
        margin-bottom: .25rem;
      }
    }
  }

  .edit-button {
    margin-right: auto;
  }
}

.CreateUserRow {
  display: flex;
  
  & > * {
    margin-right: 2.5rem;
  }
}

