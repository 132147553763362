.VersionManager {
  .VersionInput {
    &:not(:last-of-type) {
      margin-bottom: .5rem;
    }
  }
}

.VersionInput {
  display: flex;
  height: 2rem;
  align-items: center;
  

  label {
    display: flex;
    align-items: center;
    
    .label-text {
      width: 7rem;
      margin-right: 1rem;
    }

    .version-container {
      // width: 10rem;
      // display: flex;

      span.version {
        padding-left: 12px;
        margin-right: .75rem;
      }

      .version {
        max-width: 8rem;
        display: inline-flex;
        // justify-content: center;
      }
    }

    .version-editor {
      display: flex;

      .buttons-container {
        display: flex;

        button {
          // margin-right: 0.5rem;
        }
      }
    }
  }
}