@import "../styles/variables";
@import "../styles/mixins";

.page {
  // padding-bottom: 3rem;
  min-height: 100%;
  &__title {
    margin-bottom: 1.5rem;
    font-size: 2.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
  }

  .block {
    &:not(:last-of-type) {
      padding-bottom: 2rem;
      margin-bottom: 1.5rem;
    }
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  padding: 0;

  .card {
    box-sizing: border-box;
    width: calc(33% - 1rem);
    margin-right: 1rem;
    // height: 100%;
    padding-bottom: 2rem;
    margin-bottom: 1rem;

    @include respond-tablet-landscape {
      width: calc(50% - 1rem);
    }

    @include respond-phone-landscape {
      width: calc(100% - 1rem);
    }

    .ant-card-actions {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

