@import '../../styles/mixins.scss';

.GlobalFrontlinePage {
  padding: 0rem 0 5rem 0;

  @include respond-tablet-portrait {
    padding-left: 25%;
  }

  .top-container {
    display: flex;
    margin-bottom: 1rem;

    .toggle-container {
      .toggle-label {
        margin-right: 1rem;
      }
      margin-left: auto;
    }
  }

  .switch-container {
    display: flex;
    align-items: center;
    
    .switch-label {
      font-weight: 500;
      margin-right: 1rem;
    }
  }

  .ant-list {
    margin-top: 2rem;

    .ant-list-item {
      .item-text {
        min-width: 6rem;
      }

      display: flex;
      .button-row {
        margin-left: 1rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        
        .ant-btn {
          margin-left: 1rem;
          margin-right: 1rem;
        }
      }
    }
  }
}