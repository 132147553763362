.URLInput {
  display: flex;
  align-items: center;
  height: 2rem;

  &__icon {
    margin-left: .5rem;
  }

  &__label {
    width: 14rem;
  }

  &__input {
    width: 20rem;
  }

  &__buttons-container {
    display: flex;
  }
}

.URLInput--empty .URLInput__input {
  color: rgba(0, 0, 0, 0.35);
}