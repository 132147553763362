@import "../../styles/variables";
@import "../../styles/mixins";

$page-width: 1250px;

.Main {

  .undefined {
    background-color: red;
    color: red;
  }

  .MainNav {
    max-width: $page-width;
    margin: 0 auto;
  }

  .header {
    background-color: white;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
  
  .content {
    margin-top: 64px;
    padding: 3rem 0;

    .page {
      max-width: $page-width;
      padding: 0 0.5rem;
      margin: 0 auto;

      .page-title {
        font-size: 1.75rem;
        text-transform: uppercase;
        margin-bottom: 2rem;
      }

      .section-title {
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }
    }

    @include respond-tablet-landscape {
      padding-top: 4rem;
      padding-right: 2rem;
      padding-left: 2rem;
    }

    @include respond-tablet-portrait {
      padding-left: .5rem;
      padding-right: .5rem;
    }
  }
  // display: flex;
  // min-height: 100vh;
  // flex-direction: row;
  // max-width: $max-width-layout;

  // @media only screen and (max-width: $phone) {
  //   flex-direction: column;
  // }

  // main {
  //   flex-grow: 1;
  //   display: flex;
  //   flex-direction: column;
  //   @media only screen and (max-width: $tablet-portrait) {
  //     order:1;
  //   }    
  // }

}

