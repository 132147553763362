.FailurePage {
  display: flex;
  justify-content: center;
  // align-items: left;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 7rem;
  position: absolute;
  margin-right: 10%;
  left: 35%;

  p {

  }

  button {
    width: 10rem;
  }
}