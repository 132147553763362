.ErrorPage {
  .error-container {
    width: 100%;
    background-color: #dad9d9;
    padding: 1rem;
    margin-bottom: 1rem;

    p {
      padding: 0;
      margin: 0;
    }
  }
}