.ImageGen {
  .progress-bar {
    width: 10rem;
  }

  .content-container {
    // display: flex;
    // flex-wrap: wrap;
  }

  .status {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 1rem;
    display: inline-block;
  }

  .status-indicator {
    margin-right: 0.5rem;
    font-weight: bold;
    
    &.OFFLINE {
      color: #fe1313;
    }

    &.ONLINE {
      color: #52c41a;
    }
    
    &.PROBLEM {
      color: #faad14;
    }
  }

  .QueueStatus {
    display: flex;
    align-items: center;

    & p {
      padding: 0;
      margin: 0;
    }

    .queue-status-title {
      cursor: pointer;
      font-size: 16px;
      margin-right: 2rem;
      padding: 0;
    }

    .queue-messages {
      margin-right: 1rem;
    }
  }

  .machines-container {
    & > .ant-card-head-wrapper {
      display: flex;
      .ant-card-head-title {
        flex: 1;
      }

      .ant-card-extra {
        flex: 2;
      }
    }
    
    & > .ant-card-body {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      justify-content: flex-start;
      background: #fafafa;

      .ant-card-loading-content {
        padding: 1rem;
        background-color: white;
        width: 100%;
      }
    }

    .MachineStatusTable {
      width: 49%;
      margin-top: 1rem;

      .machine-name {
        font-weight: bold;
      }

      &:nth-of-type(1), &:nth-of-type(2) {
        margin-top: 0;
      }

      &:nth-of-type(2n) {
        margin-left: auto;
      }
    }

    .MachineStatusTable + .MachineStatusTable {
      // margin-left: auto;
    }

    .MachineStatus-card {
      // margin-left: 5%;
    }
  }
}

.QueueStatus {
  width: 100%;
  .message-count {
    font-weight: bold;
  } 

  .status-checker-text {
    margin-top: 0.5rem;
  }
}

.MachineStatus {
  &.MachineStatus-card {
    width: 50%;

    .machine-name {
      // min-width: 155px; 
    }

    .status-indicator {
      min-width: 75px;
    }
  }
  .ant-card-body { display: none; }
  .status-indicator {
    margin-right: 0.5rem;
    font-weight: bold;
    
    &.OFFLINE {
      color: #fe1313;
    }

    &.ONLINE {
      color: #52c41a;
    }
    
    &.PROBLEM {
      color: #faad14;
    }
  }

  .status-icon {
    margin-right: 0.5rem;;
  }
  
  .machine-info {
    display: flex;
    align-items: center;
    .status-icon {
      font-size: 1.2rem;
    }
  }

  .instances-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .instance {
      flex-basis: 50%;
      display: flex;
      margin-top: 0.5rem;
      align-items: center;
    }

    .instance-priority {
      margin-left: 0.5rem;
      text-transform: capitalize;
    }
  }
}