.UserSelection {
  margin-top: 2rem;
  width: 35rem;


  .user-selection-list-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    &.ant-list-item {
      border-bottom: 1px solid rgba(0, 0, 0, .1);

      &:last-child {
        border: none !important;
      }
    }
  }

  .user-email {
    word-break: break-all;
    margin-right: 1.5rem;
  }

  .switch-container {
    margin-right: 2rem;
    .switch-label {
      margin-right: 1rem;
      white-space: pre;
    }
  }

  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}