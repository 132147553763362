.component-card {
  position: relative;
  scrollbar-width: thin;
  width: 100%;
  // min-height: calc(100vh - 20rem);

  &__title {
    margin-bottom: 1.2rem;
  }

  .sub-group {
    // margin-top: 1rem;
    // padding-left: 1rem;
    // padding-bottom: .5rem;
  }

  .suggestion-text {
    text-transform: uppercase;
    font-weight: 600;
    color: #666666;
    margin: 0;
  }

  .readmore-input-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 0;
  }

  .readmore-title {
    margin-top: 2rem;
  }

  .ReadMoreInput {
    .URLInput__input {
      width: 25rem;
    }
  }

  .ReadMoreInput + .ReadMoreInput {
    margin-top: 1rem;
  }

  .air-purifier-editable-link {
    margin-top: 3rem;
  }

  .block-title {
    display: flex;
    .title-error {
      color: #ff2a2a;
      display: inline-block;
      margin-left: auto;
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__main-tab {
    & > .ant-tabs-bar .ant-tabs-nav {
      // The tab panel nav
      font-weight: 600;

      // font-size: 1rem;
    }

    & > .ant-tabs-bar .ant-tabs-tab-active {
      font-weight: 600;
    }
  }

  .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
    // Individual tabs
    min-width: 4rem;
    display: inline-flex;
    justify-content: center;
  }

  .tab-pane {
    padding: 1.5rem 0;
  }

  .tab-pane-text {
  }

  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    .tab-pane-text-off {
      // color: red;
    }
  }

  &__secondary-tab {
    & > .ant-tabs-bar .ant-tabs-nav {
    }
  }

  .error-message {
    position: absolute;
    right: 1rem;
    // font-weight: bold;
    top: 0rem;
    color: #ff2a2a;
  }

  .ant-card-body > .error-message {
    top: 1.75rem;
  }

  span.required {
    color: red;
  }

  .family-collapse {
    padding-left: 0.5rem;
  }

  .ant-collapse {
    background-color: white;
    .ant-collapse-item {
      position: relative;

      .ant-collapse-header {
        font-size: 1rem;
        font-weight: 500;
        padding-left: 1.25rem;
        padding-bottom: 1rem;

        .ant-collapse-arrow {
          left: -5px;
        }
      }
    }
    .ant-collapse {
      .ant-collapse-header {
        // inner header
        font-size: 1rem;
      }
      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 0;
          padding-bottom: 1rem;

          .error-message {
            top: 1rem;
          }

          .CheckboxGroup {
            position: initial; // For error message positioning.
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .options-container {
      .ant-checkbox-wrapper {
        padding: 0.6rem;
      }
    }
  }

  @media only screen and (max-width: 768px) {
  }
}

.ToggleTypeButton {
  display: flex;
  align-items: center;

  &__label {
    display: inline-block;
    margin-left: 0.5rem;
  }
}

.CopItemWithDisplays {
  // background-color: ;
  .cop {
    font-weight: 600;
    color: rgba(0,0,0,.85);
    font-size: 1rem;
  }

  &:not(:first-of-type) {
    margin-top: 1.5rem;
  }

  .inner-items {
    border-left: 2px solid #d9d9d9;
  }

  .inner-item-container {
    position: relative;
    margin-bottom: 1rem;

    box-sizing: border-box;
    padding-left: 1.5rem;
    padding-top: 0.5rem;

    &:first-of-type {
      padding-top: 1rem;
    }

    .items {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: flex-start;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 1rem;
      right: 0;
      bottom: -0.5rem;
      display: block;
      content: "";
      // width: 100%;
      // height: calc(100% + 0.5rem);
      border-bottom: 1px solid #f0f1f5;
    }
  }
}
