@import "../../styles/Variables";
@import '~antd/dist/antd.css';

.ant-message {
  .ant-message-notice-content {
    max-width: 40rem;
    font-weight: 500;
    font-size: .9rem;
  }
}
