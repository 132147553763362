.WelcomePageManager {
  .toggles-container {
    .SectionToggle {
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }
  }

  .url-manager {
    display: flex;
  
    &:not(:last-of-type) {
      margin-bottom: .5rem;
    }

    .URLSelector {
      margin-left: 2.5rem;
    }
  }
}

.SectionToggle {
  display: flex;
  justify-content: space-between;
  width: 17rem;

  &:hover {
    cursor: pointer;
    background-color: aliceblue;
  }
}

.URLSelector {
  display: flex;
  align-items: center;
  height: 2rem;
  max-width: 35rem;

  &__icon {
    margin-left: .5rem;
  }
}