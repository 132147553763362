.DashboardPage {
  padding-bottom: 3rem;
  // margin-bottom: 5rem;

  .switch-container {
    display: flex;
    align-items: center;
    
    .switch-label {
      font-weight: 500;
      margin-right: 1rem;
    }
  }

  .FrontlinesDisplay {
    margin-bottom: 1rem;
  }

  .bottom-block {
    display: flex;
    justify-content: space-between;

    .right-buttons-container {
      display: flex;
      // Gap for items inside
      & > * {
        &:not(:last-of-type) {
          margin-right: 1rem;
        }
      }
    }
    
    .FrontlineCreator {
      margin-top: 0;
      justify-content: flex-end;
    }
  }
}