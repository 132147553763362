.ManageLocalDesignsPage {
 
  & > .block {
    margin-top: -3rem;
  }

  .affix-content {
    height: 5rem;
  }

  .DesignFields {
    // background-color: white;
  }
}

.DesignCard {
  .design-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .design-name-editor {
    display: flex;

    .design-name-input {
      
    }

    .buttons-container {
      z-index: 99;
      display: flex;
      flex-direction: column;
    }
  }

  .design-image-container {
    height: 15rem;
    width: 100%;
    text-align: center;
    
    .design-image {
      max-height: 100%;
      max-width: 100%;
    }
  }
}