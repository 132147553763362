.UpdateAllPage {
  p {
    font-size: .9rem;
  }

  .buttons-container {
    .ant-btn {
      margin-right: 1rem;
    }
  }
}

.UpdateProgressContainer {
  margin-top: 2rem !important;
  width: 40%;
}

.ProgressItem {
  .anticon {
    font-size: 1rem;
  }

  .ant-timeline-item-content {
    width: 100%;
    display: flex;
  }

  
  &-description {
    width: 85%;
  }
  &-error {
    color: red;
    margin-left: 1rem;
  }
  &-timer {
    min-width: 2rem;
    display: inline-block;
    margin-left: auto;
  }
  
}

.failed-frontline {
  margin: 0;
  &:not(:last-of-type) {
    margin-bottom: 0.75rem;
  } 
  
  .failed-frontline-info {
    margin: 0;
    padding: 0;
  }
  
  .failed-frontline-reason {
    margin: 0;
  }
}

.FailedProducts {
  display: flex;
  flex-wrap: wrap;

  .failed-product {
     margin-right: .5rem;
     margin-bottom: 1rem;
  }
}