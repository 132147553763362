@import './variables.scss';
// Media queries

@mixin respond($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

@mixin respond-phone {
  @media only screen and (max-width: $phone) {
    @content;
  }
}

@mixin respond-phone-landscape {
  @media only screen and (max-width: $phone-landscape) {
    @content;
  }
}

@mixin respond-tablet-portrait {
  @media only screen and (max-width: $tablet-portrait) {
    @content;
  }
}

@mixin respond-tablet-landscape {
  @media only screen and (max-width: $tablet-landscape) {
    @content;
  }
}

@mixin respond-small-desktop {
  @media only screen and (max-width: $desktop-small) {
    @content;
  }
}

@mixin respond-medium-desktop {
  @media only screen and (max-width: $desktop-medium) {
    @content;
  }
}

@mixin respond-large-desktop {
  @media only screen and (max-width: $desktop-large) {
    @content;
  }
}