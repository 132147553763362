.ProductsPage {

  .lists-container {
    display: flex;
    justify-content: space-start;
    // align-items: center;
    width: 100%;
    margin-right: -.5rem;
    flex-wrap: wrap;

    .ProductList {
      box-sizing: border-box;
      margin-right: .5rem;
      margin-bottom: 1rem;
      margin-top: .5rem;
      width: calc(100% / 3 - 0.5rem);
    }
  }
}

.ProductList {
  padding: 0 1.5rem;
  width: 20rem;
  // background: white;
  
  .ant-card-body {
    max-height: 25rem;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  .ProductListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}