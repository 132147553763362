html {
  // overflow-y: scroll;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
code {
font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;

  .App {
    height: 100%;
    
    .Main {
      height: 100%;
      
      .ant-layout {
        min-height: 100%;
      }
    }
  }
}

a.disabled {
  color: currentColor;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;

  &:hover {
    color: currentColor;
  }
}





