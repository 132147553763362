.ManageLocalThemesPage {
  .color-block {
    width: 2rem;
    height: 2rem;
    border-radius: 5px;
  }

  .theme-description {
    font-style: italic;
  }

  .theme-designs {
    // font-weight: 600;
  }

  .buttons-container {
    margin-left: -15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.new-theme-modal {
  .ant-modal-body {
    min-height: 18.5rem;
  }
}

.new-theme-form {
  .ant-form-item-label {
    min-width: 8.5rem;
    text-align: left;
  }

  .ant-radio-button-wrapper-checked {
    outline: 1px solid #1890ff;
  }
}