.Users {
  .users-table {
    // max-width: 45rem;

    .save-button {
      margin-left: .45rem;
    }
    
    .ant-table-footer {
      background: none;
      display: flex;
      padding: 0;

      & > div {
        padding: 1rem;
      }

      .email-input-container {
        width: 40%;
      }

      .role-select-container {
        width: 30%;
      }

      .email-input {
        border: none;
        background: none;
        border-bottom: 1px solid #1890ff;
        border-radius: 0;
        padding: 0;
      }

      .footer-buttons {
        // margin-left: auto;
      }
    }
  }

  .role-tag {
    width: 5rem;
    height: 2rem;
    font-size: .9rem;
    // font-weight: bold;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}