@import "../../styles/mixins.scss";

.ManageOfferingPage {

  &.page {
    // Force scroll so the layout does not jump around when changing tabs
    min-height: calc(100vh - 12rem);
  }

  .title {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  .ant-tabs-tabpane > .CheckboxGroup > .CheckboxGroup__title {
    margin-top: 1.5rem;
  }

  .ant-card {
    border-top: none;
    overflow-y: auto;

    @include respond-tablet-portrait {
      width: 100%;
    }

    .CheckboxGroup {
      &:not(:first-of-type) {
        margin-top: 1.5rem;
      }
      
      padding-bottom: 0.5rem;
      
      &:not(:last-of-type) {
        border-bottom: 1px solid #f0f1f5;

      }
    }
  }

  .ant-tabs-bar {
    margin: 0;
    
    .ant-tabs-tab-active {
      border-bottom: none;
    }
  }

  .save-button-container {
    position: fixed;
    bottom: 0rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: rgba(white, 0.75);
    z-index: 1000;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    
    .save-button {
      margin-right: 59rem;
    }
  }

  .save-button {
    
  }
}