.APIDashboardFilters {
  .filter {
    padding: 10px 5px;

    .filterHeader {
      text-transform: uppercase;
    }
  }

  .fullWidth {
    width: 100%;
  }

  .halfWidth {
    width: 50%;
    text-align: center;
  }
}