.ant-list-empty-text {
  text-align: left;
  padding-left: 0;
}

.ant-breadcrumb-link {
  a {
    display: inline-block; // This is needed or capitalize won't work
    text-transform: capitalize;
  }
}

// For some reason the switch styles are broken,
// this fixes them.
// TODO Check again after antd update
.ant-switch {
  .ant-switch-inner {
    margin: 0 6px 0 24px;
  }
}

.ant-switch.ant-switch-checked {
  .ant-switch-inner {
    margin: 0 24px 0 6px;
  }
}

.ant-card {
  .card-container {
    &__row {
      display: flex;
      justify-content: space-between;
    }
    
    .card-container__row + .card-container__row {
      margin-top: 1.5rem;
    }
  }
}