.Mobile {

  .chartAndFilters {
    display: flex;
    flex-direction: row;
    width:100%;

    .chart {        
      flex-basis: 80%;
    }
    .filters {
      flex-basis: 20%;        
    }
  }

  .fullWidth {
    width:100%;
  }
  
  .halfWidth {
    width:50%;
    text-align: center;
  }
  
  .filter {
    padding: 10px 5px;
  
    .filterHeader {
      text-transform: uppercase;
    }
  
    .filterItem {
  
    }
  
  }

}