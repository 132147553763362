@import "../../styles/variables";
@import "../../styles/mixins";

.MainNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: 100%;
  background-color: white;

  @include respond-tablet-landscape {
    
  }
  &__menu{
    width:100%;
  }

  &__menu.ant-menu {
    position: relative;
    display: flex;

    @include respond-tablet-landscape {
      z-index: 5;
      padding-top: 1rem;
      padding-right: 0;
      padding-left: 0.5rem;
      height: auto;


      .ant-menu-item {
        a {
          display: flex;
          flex-direction: column;

          .anticon {
            display: block;
            font-size: 2rem;
            margin: 0;
          }
        }
      }
    }

    @include respond-tablet-portrait {
      // padding: 0;
      padding-right: 0;
      padding-left: 0.2rem;

      .ant-menu-item {
        padding: 0 .6rem;
      }
    }
  }

  &__extra {
    display: flex;
    align-items: center;
    
    .user-avatar-container {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: 2rem;
      cursor: pointer;
      
      .ant-avatar {
        background-color: $light-blue;
        font-size: 1.2rem;

        @include respond-tablet-landscape {
          height: 2.5rem;
          width: 2.5rem;
          padding-top: .1rem;
          font-size: 1.5rem;
        }
      }
    }

    .helpfile-link-container {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      cursor: pointer;

      .helpfile-link {
        display: flex;
        justify-content: center;
        align-items: center;
        color: inherit;

        &:hover {
          color: $light-blue;
        }
        
        .helpfile-icon {
          font-size: 1.9rem;

          @include respond-tablet-landscape {
            font-size: 2.5rem;
          }
        }
      }
    }
  }
}

.ant-popover {
  .ant-popover-title {
    margin: .25rem 0
  }
  
  .ant-popover-inner-content {
    display: flex;
    justify-content: center;
  }
}