@import '../../styles/mixins.scss';

.CheckboxGroup {
  position: relative;
  &__title {
    margin-bottom: 0.4rem;
  }

  .main-title {
    font-size: 1.3rem;
  }

  .hidden {
    display: none;
  }

  .ant-checkbox-group {
    width: 100%;
  }

  .category {
    .category-name {
      text-transform: capitalize;
      font-weight: 600;
      font-size: 0.9rem;
    }

    &:not(:last-of-type) {
      margin-bottom: 1.5rem;
    }
  }

  .sub-group {
    border-left: 2px solid #d9d9d9;
    // background-color: #f5f5f5;
    padding: 1rem 1.5rem;
    box-sizing: border-box;
  }


  .options-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: flex-start;

    .ant-checkbox-wrapper {
      margin-left: 0;
      padding-right: 1rem;
      display: inline-flex;
      align-items: center;

      .ant-checkbox {
        margin-top: 4px;
      }

      .ant-checkbox + span { // The text element of the checkbox.
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }

      &:not(:last-of-type) {
        // margin-right: 1rem;
      }
    }
  }

  .check-all-panel {
    // margin-bottom: 1rem;
    font-weight: 600;
    
    .ant-checkbox-wrapper {
      color: rgba(0, 0, 0, 0.85);
      font-size: 1rem;
    }
  }


  

  .sapId {
    font-weight: bold;
  }

  h5 {
    font-size: .9rem;
    margin-bottom: .9rem;
  }
}

.CheckboxGroup.Landing {
  text-transform: capitalize;
}

.ReverseCheckbox {
  z-index: 1; // otherwise label is not sometimes clickable
  &.ant-checkbox-wrapper + &.ant-checkbox-wrapper {
    margin-left: 0;
  }
  
  // Styles for checkbox selected and not selected state have been reversed so checkbox that appear to be
  // unchecked actually set restrictions.
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: white;
      border-color: #d9d9d9;
    }
  }

  .ant-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff;
  }

  .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }

  .ant-checkbox-disabled {
    .ant-checkbox-inner::after {
      border: none;
    }
  }

  .ant-checkbox::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border-radius: 2px;
    visibility: hidden;
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    content: '';
  }

  &.disabled-selected {
    .ant-checkbox-disabled {
      .ant-checkbox-inner::after {
        border: 2px solid #a9a9a9;
        border-top: 0;
        border-left: 0;
      }
    }
  }
}