@import "../../styles/variables";
@import "../../styles/mixins";

.ProductSelection {
  margin-right: -1rem;
  display: flex;
  flex-wrap: wrap;

  .dropdown-button {
    font-weight: 600;
    color: white;
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .list-container {
    width: 100%;
    position: relative;
    padding-top: 3rem;

    h2 {
      display: flex;
      align-items: center;
      position: absolute;
      z-index: 3;
      top: 0;
      // left: 1rem;
      padding-left: 1rem;
    }

    .sticky {
      position: fixed;
      top: 4.5rem;
      // left: 1rem;
      background-color: white;
      box-shadow: 2px 4px 4px -1px rgba(0,0,0,0.45);
      z-index: 3;
    }
  }

  .ProductList {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;

    &-container {
      width: 100%;
      display: flex;
      padding: 0;
      flex-wrap: wrap;
      margin-right: -1rem;
    }

    .product-select {
      min-width: 13rem;
      max-width: 25rem;
    }

    .ProductCard {
      box-sizing: border-box;
      width: calc(50% - 1rem);
      margin-right: 1rem;
      margin-bottom: 1rem;

      .visibility-toggle {
        
      }

      .delete-button {
        margin-left: 1rem;
        margin-right: auto;
      }
      
      @include respond-tablet-portrait {
        width: calc(100% - 1rem);
      }
      
      .ant-card-head-wrapper {
        .ant-card-head-title {
          margin-right: 1rem;
        }
      }
    }

    .switch-container {
      display: flex;
      align-items: center;
      margin-left: auto;

      & > span {
        display: inline-block;
        max-width: 11rem;
        text-align: right;
      } 

      button {
        margin-left: 1rem;
      }
    }
  }

  .product-group-tabs {
    width: 100%;
    &--collapsed {
      .ant-tabs-bar {
        display: none;
      }
    }

    .ant-tabs-nav {
      // font-weight: 600;
    }
  }
}

.ProductCard--minimized {
  .ant-card-head-title {
    max-width: 58%;
    white-space: normal;
  }

  .ant-card-extra {
    margin-left: 0;
    width: 42%;

    .card-extra-container {
      width: 100%;
      display: flex;
    }
  }

  .ant-card-body {
    display: none;
    
  }
}

      
.ant-btn.ant-btn-danger, .ant-btn.ant-btn-danger:focus {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  color: white;

  &:hover {
    background-color:  #ff7875;
  }
}

.ant-modal {
  .ant-modal-content {
    .ant-modal-body {
      overflow-y: auto;
      scrollbar-width: thin;
      max-height: 17.5rem;
    }
  }
}

.version-row {
  padding: .5rem .5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-of-type) {
    border-bottom: 1px solid #cccccc55;
  }

  .first-column {
    min-width: 6rem;
  }

  .current {
    font-weight: bold;
    text-align: center;
  }

  .info-fields {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    margin-left: 1.2rem;
    height: 3.5rem;
    // text-align: left;

    .info-row {
      flex: 0 0 100%;
      .info-label {
        margin-right: 5px;
        font-weight: 500;
      }

      .info-field {
          
      }
    }
  }
}

.CustomTabBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: 2rem;

  .tab-pane {
    position: relative;
    padding: 6px 12px;
    margin-bottom: 4px;
    margin-right: 4px;
    cursor: pointer;
    font-size: 15px;

    &::before {
      content: '';
      display: block;
      height: 14px;
      top: 12px;
      width: 1px;
      background-color: gray;
      position: absolute;
      left: -2px;
    }
    
  }

  .tab-pane:hover, .tab-pane-selected {
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 12px;
      right: 12px;
      bottom: 2px;
      z-index: 0;
      height: 2px;
      background-color: #1890ff;
    }
  }
}