.ReleasesPage {
  .release-group {
    &:not(:last-of-type) {
      margin-bottom: 1.5rem;

      .releases-container {
        border-bottom: 1px solid rgba(black, 0.07);
      }
    }

    .release-group-title {
      margin-bottom: 1rem;
    }

    .releases-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .switch-container {
        display: flex;
        align-items: center;
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;
        .switch-label {
          margin-right: 1rem;
          font-weight: 500;
        }

        .switch-group {
          display: flex;
          flex-direction: column;

          .json-switch {
            margin-top: 0.25rem;
            min-width: 60px;
          }
        }
      }
    }
  }
}