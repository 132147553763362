.FrontlineCreator {
  display: flex;
  
  .creating-new-container {
    display: flex;

    .country-select {
      width: 15rem;
      margin-right: 1rem;
    }
  }
  
}