
// responsive threshold values
$desktop-large: 1960px;
$desktop-medium: 1600px;
$desktop-small: 1200px;
$tablet-landscape: 1024px;
$tablet-portrait: 768px;
$phone-landscape: 600px;
$phone: 375px;

// layout measurements
$max-width-layout: 2560px;

$viewer3d-min-height: 350px;

// main navigation measurements
$xl-width-main-nav: 100px;
$l-width-main-nav: 100px;
$md-width-main-nav: 50px;
$sm-width-main-nav: 100%;

// edit panel measurements
$xl-width-edit-panel: 380px;
$l-width-edit-panel: 280px;
$md-width-edit-panel: 260px;
$sm-width-edit-panel: 100%;

$editpanel-padding: 20px;

// nav bar measurements
$nav-bar-height: 40px;
$nav-bar-height-lg: 50px;

// downloadspecs measurements
$width-download-specs: 30%;

// colors
$kone-blue: #0060AD;
$light-blue: #0071B9;
$lighter-blue: #177CBD;
$lightest-blue: #86C2E6;
$primary-color: #1890ff;
$error-color: #ff2a2a;


$nav-bar-bg: #E0E4E6; 

$desc-text :#BABDBF;

$darkest-grey: #373737;
$darker-gray: #3B4245;
$dark-gray: #8D9194;
$gray: #E0E4E6;
$light-gray :#BABDBF;
$lighter-gray: #F4F4F5;

$app-bg: #EDEFF0;
$card-bg: #FFFFFF;
$border-color: #B3B3B3;
$button-border-radius: 0.25rem;
$header-bg: #FFFFFF;
$header-height: 4.5rem;
$edit-panel-bg: #FFFFFF;

@font-face {
    font-family: 'KoneFont';
    font-weight: normal;
    src: url('./fonts/KONE_Information.eot'); /* IE9 Compat Modes */
    src: url('./fonts/KONE_Information.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/KONE_Information.woff') format('woff'), /* Pretty Modern Browsers */
         url('./fonts/KONE_Information.ttf')  format('truetype'), /* Safari, Android, iOS */
         url('./fonts/KONE_Information.svg') format('svg'); /* Legacy iOS */
}
