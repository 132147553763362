.EditableInput {
  display: flex;
  min-height: 2rem;
  align-items: center;

  &--invalid {

  }

  &__container {
    min-width: 12.4rem;
  }
  
  &__label {
    display: flex;
    align-items: center;
    
    .label-text {
      width: 7rem;
      margin-right: 1rem;
    }
  }

  &__text {
    padding-left: 12px;
    margin-right: .75rem;
    max-width: 8rem;
    white-space: pre-line;
    padding-top: 4px;
    padding-bottom: 4px;
    // border: 1px solid black;
    // width: 100%;
    display: inline-flex;
  }

  &__input {
    &.ant-input[disabled] {
      color: rgba(0, 0, 0, 0.65);

      &.EditableInput--empty {
        color: rgba(0, 0, 0, 0.35);;
      }

      &:hover {
        cursor: default;
      }
    }
  }

  &__text-container {
    display: flex;
    align-items: center;
  }

  &__editor {
    display: flex;

    .buttons-container {
      display: flex;
      align-items: center;
    }
  }

  &__icon.anticon {
    margin-left: .5rem;
    display: inline-flex;
    align-items: center;
  }
}